let api_urls = {
  client_id: "2",
  client_secret: "dhGLOnQlLZhDPJmRaNXXJcAOEcDZnqMtm7VGpc5t",
  base_url: "https://api.staging.iclinicpay.com.br",
  base_api: "https://api.staging.iclinicpay.com.br/api/v1/",
  spreadsheet_importer_api:
    "https://spreadsheet-importer-api.medicinaesolutions.com/homolog",
  spreadsheet_importer_ws_api:
    "wss://spreadsheet-importer-ws-api.medicinaesolutions.com/homolog"
};

export const environment = {
  production: false,
  title: "Staging",
  api: api_urls,
  name: 'staging',
  assignmentTermRequireGuarantorValue: 200000
};
